import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flashcards"
export default class extends Controller {
  static targets = [ "response" ]

  connect() {
  }

  reveal() {
    this.responseTarget.style.display = "block";
  }

  reload() {
    window.location.reload();
  }
}
